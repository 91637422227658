// Customizable Area Start
import React from "react";

import DocusignIntegrationController, {
  Props,
} from "./DocusignIntegrationController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import RequestModal from "./../../dashboard/src/BookNotaryRequest.web";
import Loader from "../../../components/src/Loader.web";
import { Box, IconButton, Typography, Button, styled } from "@material-ui/core";
import {
  Menu,
  Close,
  ArrowBackIosRounded,
  AddRounded,
} from "@material-ui/icons";
import { localProfile, bellIcon } from "./assets";
import MiniHeader from "../../dashboard/src/MiniHeader.web";

export default class DocusignIntegration extends DocusignIntegrationController {
  constructor(props: Props) {
    super(props);
  }

  Header = () => {
    return (
      <Box  alignItems={"center"} display={"flex"} mt={"32px"}>
        <StyledIconButton onClick={this.openSideBar} data-testID="toggleButton" >
          {this.state.isSideBarOpen ? (
            <Close style={{height: "56px", width: "50px",  }} />
          ) : (
            <Menu style={{ width: "50px", height: "56px" }} />
          )}
        </StyledIconButton>
        <Box width={"calc(100vw - 74px)"}>
          <Box
            alignItems={"center"}
            display={"flex"}
            mx={"25px"}
            justifyContent={"space-between"}
          >
            <Box
              alignItems={"center"}
              display={"flex"}
              height={"48px"}
              style={{ columnGap: "8px" }}
            >
              <IconButton data-testID="backIconButton" onClick={this.goBack}>
                <ArrowBackIosRounded height={"24px"} width={"24px"} />
              </IconButton>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "32px",
                  letterSpacing: "-0.5%",
                }}
              >
                Document Signing
              </Typography>
            </Box>       
            <MiniHeader
              navigation={this.props.navigation}
              id={""}
              />
          </Box>
        </Box>
      </Box>
    );
  };

  renderMobileNavigationMenu = () => {
    return (
      <>
        {this.state.isSideBarOpen && (
          <NavigationMenu navigation={this.props.navigation} id={""} />
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <Loader loading={this.state.loader} />
        <RequestModal
          data-testID="modalOpen"
          navigation={undefined}
          id={""}
          closeModal={this.closeBookNotaryRequestModal}
          isOpen={this.state.modalOpen}
          allRequestAPI={() => { } }
          serviceData={this.state.serviceData}
          cancelReqModal={this.state.cancelBookNowReqModal}
          yesButtonClick={this.bookNowYesButtonClick}
          noButtonClick={this.bookNowNoButtonClick}
          setModal={this.setBookNowModal}
          setLoader={this.setLoader}
          isNewRequestOrEditRequestOrInviteClient={"new"}
          backToEditRequest={() => { } }
          editRequest={undefined}        />
        <Box display={"flex"} className="test1">
          <DesktopDrawerBox>
            <NavigationMenu navigation={this.props.navigation} id={""} />
          </DesktopDrawerBox>
          <MobileTabletDrawerBox>
            {this.renderMobileNavigationMenu()}
          </MobileTabletDrawerBox>
          <MainBox
            height={"100vh"}
            overflow={"auto"}
            width={this.findMainBoxWidth()}
            style={{ backgroundColor: "#F9F9F9" }}
          >
            {this.Header()}
            <MainContentBox mt={"32px"} mx={"27px"}>
              <style>
                {`#docusign-iframe: {
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }`}
              </style>
              {this.state.sender_url !== "" && (
                <iframe
                  style={{ width: "100%", height: "100%", overflow: "hidden" }}
                  id="docusign-iframe"
                  frameBorder={"0"}
                  src={this.state.sender_url}
                ></iframe>
              )}
            </MainContentBox>
          </MainBox>
        </Box>
      </>
    );
  }
}

const MainBox = styled(Box)({
  "@media (min-width: 1025px)": {
    width: "calc(100vw - 200px)",
  },
});

const MainContentBox = styled(Box)({
  height: "calc(100vh - 144px)",
  "@media (min-width: 1025px)": {
    display: "flex",
  },
});

const DesktopDrawerBox = styled(Box)({
  "@media (max-width: 1024px)": {
    display: "none",
  },
  display: "flex",
});

const MobileTabletDrawerBox = styled(Box)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const StyledIconButton = styled(IconButton)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const StatusBox = styled(Box)({
  "& .inprogress": {
    background: "#FEF3C7",
    color: "#D97706",
  },
  "& .statusBox": {
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 12px",
  },
});
// Customizable Area End
// Customizable Area End
